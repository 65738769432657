@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  --tw-ring-color: none !important;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  margin: 3px 0;
  border-radius: 10px;
  background-color: #e3e3e3;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-color: #ababab;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-dropdown {
  padding-left: 5px !important;
}

.ant-card-body {
  padding: 0 !important;
}

.ant-btn-icon {
  margin-inline-end: 5px !important;
}

.ant-dropdown-menu-item {
  padding: 5px 10px !important;
}

.ant-dropdown-menu-item:hover {
  background-color: unset !important;
}

.ant-dropdown-menu {
  padding: 8px 0px !important;
}

.ant-notification-notice-message {
  color: white !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-right: 1rem !important;
}
.ant-notification-notice-close {
  color: white !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.ant-segmented-item-label {
  padding: 4px 12px !important;
  font-weight: 500;
}

.ant-segmented-item-selected {
  background-color: rgb(239 68 68) !important;
  color: white !important;
  font-weight: 500 !important;
}

.ant-table-content,
table {
  table-layout: fixed !important;
}

.custom-font-size {
  font-size: 12px;
}

.custom-width {
  width: 40px;
}

.size-width {
  width: 25px;
}

.custom-header th {
  height: 10px;
}

.notification {
  background-color: rgb(239 68 68) !important;
  color: white !important;
}

.ant-checkbox {
  height: 17px;
  width: 17px;
}

.small-font {
  word-wrap: break-word; /* Allows words to break across lines */
  /* Optionally, you can add more styling such as height, width, or padding */
  min-height: 40px; /* Example of setting a minimum height */
}

.selected-row {
  background-color: rgb(233, 233, 103);
}

.unSelected-row {
  background-color: floralwhite;
}

.entry-table-v2 {
  min-width: 1200px !important;
  overflow-x: auto;
}

.scroll-v2 {
  overflow-y: scroll;
  height: 300px;
}

.custom-w-md {
  width: 100px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding: 5px;
  height: 50px;
  background-color: #fafafa;
  font-family: Arial, Helvetica, sans-serif;
}
.custom-w-lg {
  width: 200px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding: 5px;
  height: 50px;
  background-color: #fafafa;
  font-family: Arial, Helvetica, sans-serif;
}
.custom-w-sm {
  width: 50px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding: 5px;
  height: 50px;
  background-color: #fafafa;
  font-family: Arial, Helvetica, sans-serif;
}
.custom-w-xsm {
  width: 55px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding: 5px;
  height: 50px;
  background-color: #fafafa;
  font-family: Arial, Helvetica, sans-serif;
}
.custom-inp {
  width: 100%;
  border: none;
  border-bottom: 2px solid lightgray;
}
.custom-select {
  width: 100%;
  border: none;
  border-bottom: 2px solid lightgray;
}

.negative-size {
  color: redBase;
}

.positive-size {
  background-color: white;
}

.custom-text {
  resize: none;
  overflow: hidden;
  font-size: 13px;
  line-height: 14px;
}

.ant-table-cell {
  padding: 3px !important;
  white-space: pre-wrap !important;
}

.print-div {
  width: 350px;
  border: 2px solid black;
}

.table1 {
  border: 1px solid black;
  border-collapse: collapse;

  width: 100%;
}

.table1 td {
  height: 52px !important;
}

.td1 {
  border: 1px solid black;
  border-collapse: collapse;
}

.th1 {
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 10px;
  text-align: center;
}

.react-select__control {
  transform: translate3d(0, 0, 0); /* Create a new stacking context */
  z-index: 1000;
}

.ant-table-thead {
  transform: translate3d(0, 0, 0); /* Create a new stacking context */
  z-index: 900;
}

.highlight-row {
  background-color: rgb(233, 233, 103); /* or any color you prefer */
}

.c-input {
  border-radius: 8px;
  height: 16px;
}
.c-text-area {
  border-radius: 8px;
}

@page {
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
}

/* outward print v2 */

@media print {
  @page {
    size: 900px 800px;
  }
}
